import {
  IMyCareersPrioritize,
  IOnBoard,
  IPrioritizeItem,
  IReportCount,
  IUnlockNextGlimpse,
} from "interfaces/OnbaordingInterface";
import {
  COMPLETE_ONBOARD,
  COUNT_REPORT,
  INTRODUCTION_VIDEO,
  LIST_PRIORITIZE,
  MY_CAREER,
  START_ONBOARD,
  UNLOCK_NEXT_CAREER,
  UNLOCK_ONBOARD,
} from "./endpoint-urls";
import axios from "./http-service";
import { IntroDTO } from "screens/media/media.screen";

export const unlockOnboard = () => {
  return axios.post<any>(UNLOCK_ONBOARD).then((res) => res.data);
};

export const addIntroVideo = (data:IntroDTO) => {
  return axios.post<any>(INTRODUCTION_VIDEO,data).then((res) => res.data);
};

export const getIntroVideo = () => {
  return axios.get<any>(`${INTRODUCTION_VIDEO}`).then((res) => res.data);
};

export const unlockNextGlimpse = () => {
  return axios.post<IUnlockNextGlimpse>(UNLOCK_NEXT_CAREER).then((res) => res.data);
};

export const startOnBoard = (userPage: number) => {
  return axios
    .get<IOnBoard>(`${START_ONBOARD}?page=${userPage}`)
    .then((res) => res.data);
};
export const completeOnBoard = () => {
  return axios.post(`${COMPLETE_ONBOARD}`, {}).then((res) => res.data);
};

export const prioritizeCareer = (body: IPrioritizeItem) => {
  return axios
    .put<IMyCareersPrioritize[]>(`${MY_CAREER}/${body.id}/prioritize`, body)
    .then((res) => res.data);
};

export const ListPrioritize = () => {
  return axios
    .get<IMyCareersPrioritize[]>(`${LIST_PRIORITIZE}`)
    .then((res) => res.data);
};

export const getCountReport = () => {
  return axios.get<IReportCount>(`${COUNT_REPORT}`).then((res) => res.data);
};
