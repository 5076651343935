import Tooltip from "components/tooltip/tooltip.component";
import { ICareerItem } from "../../interfaces/CareerInterface";

const CareerItem: React.FC<ICareerItem> = ({
  name,
  icon_url,
  id,
  pressed,
  showRanking,
  rank,
  isAdmin,
}: ICareerItem) => {
  const isLongName = name?.length > 25;
  const truncatedName = isLongName ? name.slice(0, 8) + "..." : name;

  return (
    <div
      id={id}
      className="relative bg-widgetBlue w-[80px] h-[112px] flex flex-col rounded-md justify-center text-center pb-[10px] pt-[10px] items-center gap-2 shadow-lg cursor-pointer select-none"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => pressed(id)}
      onContextMenu={() => false}
    >
      {showRanking && (
        <span
          className={`absolute top-1 -left-2 rounded-full ${
            isAdmin
              ? rank
                ? rank <= 5
                  ? "bg-chart_gold"
                  : "bg-red"
                : null
              : "bg-red"
          } w-[21px] h-[21px] text-center text-sm text-white font-thin content-center`}
        >
          {rank}
        </span>
      )}
      <img
        className="bg-no-repeat sm:self-center w-[60px] h-[60px] pointer-events-none"
        src={icon_url}
        alt={name}
      />
      <Tooltip
        content={name}
        placement={"top"}
        placing="0px"
        customClass="max-w-[65px]"
      >
        <span className="text-[9px] font-normal sm:text-[10px] sm:font-bold leading-3 text-white text-balance inline-flex">
          {truncatedName}
        </span>
      </Tooltip>
    </div>
  );
};

export default CareerItem;
