import GlimpseCard from "../../components/card/card.component";
import GlimpseEducationSection from "../../components/Education-section/education-section.component";
import GlimpseCareerTrends from "../../components/career-trends/career-trends.component";
import GlimpseImpactCard from "../../components/imapct-card/impact-card.component";
import GlimpseScrollCard from "../../components/scroll-cards/scroll-card.component";
import { useEffect, useRef, useState } from "react";
import VideoPlayer from "components/video-streaming-onboard/video-player.component";
import {
  IContributionCard,
  IExpertVoiceCard,
  IEducationCard,
  IDayInLIfeCard,
  IDidYouKnowsCard,
  IProsConsCard,
  ICareerTrendCard,
  IImpactCard,
} from "interfaces/CareerInfoInterface";
import {
  IDeeperDiveQuiz,
  IGreatWork,
  IQuiz,
  IReprioritise,
  ISalary,
} from "interfaces/CareerInterface";
import BarChart from "components/charts/bar-chart.component";
import ReactPlayer from "react-player";

export const ContributionCard: React.FC<IContributionCard> = ({
  contribution_url,
  from,
}) => {
  return (
    <GlimpseCard
      title="Contribution"
      subtitle={
        from === "DeeperDive"
          ? "We asked 500+ teachers one word to describe their greatest contribution as a teacher."
          : ""
      }
      className="flex items-center"
      subtitleClass="text-sm mt-1"
      childrenClass="h-full flex justify-center items-center"
    >
      <div className="flex items-center">
        <img
          src={
            contribution_url ? contribution_url : "/assets/images/NoPreview.jpg"
          }
          className="h-auto w-fit"
          alt="Contribution"
        />
      </div>
    </GlimpseCard>
  );
};
export const ExpertVoices: React.FC<IExpertVoiceCard> = ({
  expertVoiceInfo,
  deeperDiveClass,
  onVideoEnded,
  onVideoStarted,
  onProgress,
  onPaused,
  onSeek,
  selectedVideo,
  videoSelected,
  thumbnail,
  from,
}) => {

const videoRef=useRef<ReactPlayer>(null)

  return (
    <GlimpseCard
      title="Expert Voices"
      subtitle={
        from === "DeeperDive"
          ? "We asked the experts to share their stories."
          : ""
      }
      childrenClass="h-full"
      subtitleClass="text-sm mt-1"
    >
      <div className="flex items-center justify-center gap-4 ">
        {expertVoiceInfo.length > 1 && (
          <div className="flex flex-col justify-center ">
            {expertVoiceInfo.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => videoSelected(item)}
                  className={` ${
                    selectedVideo === item.expert_voice.voice_url
                      ? "opacity-1 border-2 border-headerBlue p-1"
                      : "p-1"
                  }  block mb-1 rounded-md ${
                    item.deeper_dive_status === "completed" && "relative"
                  }`}
                >
                  {item.deeper_dive_status === "completed" && (
                    <span className="absolute z-10 top-[50px] left-[20px] md:top-[35px] md:left-[15px]">
                      <img
                        src="/assets/icons/completed_videos-tick.svg"
                        alt="tick"
                      />
                    </span>
                  )}
                  <img
                    src={
                      item.expert_voice.voice_thumbnail
                        ? item.expert_voice.voice_thumbnail
                        : "/assets/images/NoPreview.jpg"
                    }
                    alt="Contribution"
                    className={`max-w-[62px] max-h-[110px] md:max-h-[82px] ${
                      item.deeper_dive_status === "completed"
                        ? "opacity-30"
                        : "opacity-1"
                    }`}
                  />
                </div>
              );
            })}
          </div>
        )}
        {selectedVideo !== null && (
          <div className="rounded-lg">
            {/* <VideoPlayer
              video_url={selectedVideo as string}
              onStart={onVideoStarted}
              onEnd={onVideoEnded}
              onProgress={onProgress}
              onPaused={onPaused}
              onSeek={onSeek}
              className={`${
                deeperDiveClass ? " !h-[300px]" : "!h-[400px] md:!h-[300px]"
              } !w-[150px]`}
            /> */}
            <VideoPlayer
              video_url={selectedVideo as string}
              onStart={onVideoStarted}
              onEnd={onVideoEnded}
              onProgress={onProgress}
              onPaused={onPaused}
              onBuffer={() => { } }
              onSeek={onSeek}
              thumbnail={thumbnail as string}
              className={`${deeperDiveClass && " !h-[300px] !w-[180px]"}`} 
              videoRef={videoRef}            />
          </div>
        )}
      </div>
    </GlimpseCard>
  );
};
export const EducationCard: React.FC<IEducationCard> = ({
  education,
  pageNumber,
  setPageNumber,
  from,
  checkNextEnable,
}) => {
  useEffect(() => {
    if (window.innerWidth >= 768) {
      checkNextEnable(true); // Default enable next button for desktop view
    }
  }, [window.innerWidth]);
  return (
    <>
      {pageNumber && (
        <GlimpseCard
          title="Education"
          subtitle={
            from === "DeeperDive"
              ? "Required and recommended education for this career."
              : ""
          }
          subtitleClass="text-[14px] sm:text-sm"
          className="flex lg:hidden min-h-[416px]"
        >
          <div className="flex items-center justify-center h-[280px] ">
            <GlimpseEducationSection
              image={
                education[pageNumber.education].title
                  ? `/assets/icons/${education[
                      pageNumber.education
                    ].title.replaceAll(" ", "_")}.png`
                  : "/assets/icons/EducationPlaceholder.png"
              }
              title={education[pageNumber.education].title}
              description={education[pageNumber.education].type}
              duration={education[pageNumber.education].duration}
              className="text-center"
            />
          </div>
          <div className="relative bottom-0 flex justify-between">
            <div
              className="absolute"
              hidden={pageNumber.education === 0}
              onClick={() =>
                setPageNumber((prevPageNumber: { education: number }) => ({
                  ...prevPageNumber,
                  education: prevPageNumber.education - 1,
                }))
              }
            >
              <svg
                width={35}
                height={35}
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="17.5"
                  cy="17.5"
                  r="16.5"
                  transform="rotate(-180 17.5 17.5)"
                  stroke="#56686F"
                  strokeWidth={2}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.3323 18.3306L20.1311 27.6585C20.5606 28.1138 21.2571 28.1138 21.6866 27.6585C22.1045 27.2032 22.1045 26.4771 21.6866 26.0218L13.6539 17.5062L21.6866 8.99048C22.1045 8.53516 22.1045 7.79681 21.6866 7.34149C21.2571 6.88617 20.5606 6.88617 20.1311 7.34149L11.3207 16.6817C10.8912 17.137 10.8912 17.8753 11.3323 18.3306Z"
                  fill="#56686F"
                />
              </svg>
            </div>
            <div className="absolute right-[47%] top-2">
              {pageNumber.education + 1}/{education?.length}
            </div>
            <div
              className="absolute right-0"
              hidden={
                pageNumber.education === (education?.length as number) - 1
              }
              onClick={() => {
                setPageNumber((prevPageNumber: { education: number }) => ({
                  ...prevPageNumber,
                  education: prevPageNumber.education + 1,
                }));
                checkNextEnable(
                  pageNumber.education >= education?.length - 2 ? true : false
                );
              }}
            >
              <svg
                width={35}
                height={35}
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="17.5"
                  cy="17.5"
                  r="16.5"
                  stroke="#56686F"
                  strokeWidth={2}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.6677 16.6694L14.8689 7.34149C14.4394 6.88617 13.7429 6.88617 13.3134 7.34149C12.8955 7.79681 12.8955 8.52285 13.3134 8.97817L21.3461 17.4938L13.3134 26.0095C12.8955 26.4648 12.8955 27.2032 13.3134 27.6585C13.7429 28.1138 14.4394 28.1138 14.8689 27.6585L23.6793 18.3183C24.1088 17.863 24.1088 17.1247 23.6677 16.6694Z"
                  fill="#56686F"
                />
              </svg>
            </div>
          </div>
        </GlimpseCard>
      )}

      <GlimpseCard
        title="Education"
        className={`${pageNumber ? "hidden lg:flex" : ""}`}
        childrenClass="flex h-full justify-center items-center"
        subtitle={
          from === "DeeperDive"
            ? "Required and recommended education for this career."
            : ""
        }
      >
        <div className="flex justify-center text-center sm:py-8 xl:py-0">
          <div className={`flex flex-wrap gap-3 justify-center `}>
            {education
              ?.sort((a, b) => a.order - b.order)
              .map((item, index: number) => {
                return (
                  <GlimpseEducationSection
                    key={index}
                    className="w-[120px] h-[150px]"
                    title={item.title}
                    description={item.type}
                    duration={item.duration}
                    image={
                      item.title
                        ? `/assets/icons/${item.title.replaceAll(" ", "_")}.png`
                        : "/assets/icons/EducationPlaceholder.png"
                    }
                  />
                );
              })}
          </div>
        </div>
      </GlimpseCard>
    </>
  );
};

export const DayinLifeCard: React.FC<IDayInLIfeCard> = ({
  daysInLifeInfo,
  pageNumber,
  setPageNumber,
  from,
  checkNextEnable,
}) => {
  return (
    <>
      <GlimpseCard
        title="Day in the Life"
        className="relative hidden overflow-hidden lg:flex "
        childrenClass="h-full flex justify-center"
        subtitle={from === "DeeperDive" ? "Typical daily tasks." : ""}
        subtitleClass="text-sm mt-1"
      >
        <div
          className={`hidden lg:flex p-[100px] lg:px-[15px] lg:py-0 lg:justify-ccenter lg:items-center ${
            from === "DeeperDive" && "first:pl-8"
          }`}
        >
          <div
            className="absolute left-2 cursor-pointer top-[50%] z-10"
            hidden={pageNumber.dayInLife === 0}
            onClick={() => {
              setPageNumber((prevPageNumber: { dayInLife: number }) => ({
                ...prevPageNumber,
                dayInLife: prevPageNumber.dayInLife - 3,
              }));
            }}
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                transform="rotate(-180 17.5 17.5)"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3323 18.3306L20.1311 27.6585C20.5606 28.1138 21.2571 28.1138 21.6866 27.6585C22.1045 27.2032 22.1045 26.4771 21.6866 26.0218L13.6539 17.5062L21.6866 8.99048C22.1045 8.53516 22.1045 7.79681 21.6866 7.34149C21.2571 6.88617 20.5606 6.88617 20.1311 7.34149L11.3207 16.6817C10.8912 17.137 10.8912 17.8753 11.3323 18.3306Z"
                fill="#56686F"
              />
            </svg>
          </div>
          <div className="flex ">
            {daysInLifeInfo
              ?.slice(pageNumber.dayInLife, pageNumber.dayInLife + 3)
              ?.map((item, index) => (
                <div
                  key={index}
                  className={`relative ${
                    pageNumber?.dayInLife === 0
                      ? `${
                          index % 2 === 0
                            ? `${index === 2 ? "top-[38px]" : "top-[25px]"}  `
                            : "bottom-[20px]"
                        } `
                      : `${index % 2 !== 0 ? "top-[35px]" : "bottom-[20px]"} ${
                          index === 2 && "top-0"
                        } `
                  }pr-[15px] ${
                    pageNumber.dayInLife === 0 ? "first:pl-0" : "first:pl-6"
                  } 
                 
                  `}
                  // className={`relative ${
                  //   pageNumber?.dayInLife === 0
                  //     ? "odd:top-[30px]"
                  //     : "even:top-[20px]"
                  // } pr-[15px] first:pl-3`}
                >
                  {(pageNumber.dayInLife === 0 || pageNumber.dayInLife === 3) &&
                    index === 0 && (
                      <span
                        className={` ${
                          pageNumber.dayInLife === 0
                            ? " md:-top-[12px] md:left-[90px]"
                            : " md:-left-[90px] lg:-left-[100px] xl:-left-[115px] md:top-[25px] w-full"
                        } absolute block -z-10`}
                      >
                        <svg
                          width={pageNumber.dayInLife === 0 ? 564 : 620}
                          height={66}
                          viewBox="0 0 564 66"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 57.4995C80 62.4995 154 1.49924 216.5 1.49951C279 1.49978 354.5 70.5 433.5 63.9997C512.5 57.4994 563 24 563 24"
                            stroke="#AFC1CF"
                            strokeWidth={2}
                            strokeDasharray="6 6"
                          />
                        </svg>
                      </span>
                    )}
                  {/* {pageNumber.dayInLife === 3 && index === 0 && (
                    <div className="absolute block md:-left-[90px] lg:-left-[100px] xl:-left-[115px] md:top-[30px] w-full -z-10 ">
                      <svg
                        width={620}
                        height={66}
                        viewBox="0 0 564 66"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 57.4995C80 62.4995 154 1.49924 216.5 1.49951C279 1.49978 354.5 70.5 433.5 63.9997C512.5 57.4994 563 24 563 24"
                          stroke="#AFC1CF"
                          strokeWidth={2}
                          strokeDasharray="6 6"
                        />
                      </svg>
                    </div>
                  )} */}
                  <div className="flex flex-row min-w-[170px] min-h-[185px] justify-center text-center">
                    <GlimpseScrollCard
                      image={item.image_url}
                      title={item.title}
                      subtitle={item.description}
                    />
                  </div>
                </div>
              ))}
          </div>

          <div
            className="absolute right-2 cursor-pointer top-[50%] "
            hidden={
              pageNumber?.dayInLife === (daysInLifeInfo?.length as number) - 3
            }
            onClick={async () => {
              await setPageNumber((prevPageNumber: { dayInLife: number }) => ({
                ...prevPageNumber,
                dayInLife: prevPageNumber.dayInLife + 3,
              }));
              checkNextEnable(true);
            }}
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6677 16.6694L14.8689 7.34149C14.4394 6.88617 13.7429 6.88617 13.3134 7.34149C12.8955 7.79681 12.8955 8.52285 13.3134 8.97817L21.3461 17.4938L13.3134 26.0095C12.8955 26.4648 12.8955 27.2032 13.3134 27.6585C13.7429 28.1138 14.4394 28.1138 14.8689 27.6585L23.6793 18.3183C24.1088 17.863 24.1088 17.1247 23.6677 16.6694Z"
                fill="#56686F"
              />
            </svg>
          </div>
        </div>
      </GlimpseCard>

      <GlimpseCard
        title="Day in the Life"
        className="flex lg:hidden min-h-[416px]"
        subtitle={from === "DeeperDive" ? "Typical daily tasks." : ""}
      >
        <div
          className={`flex items-center justify-center ${
            from === "DeeperDive" ? "h-[300px]" : "h-[320px]"
          }   `}
        >
          <GlimpseScrollCard
            image={daysInLifeInfo[pageNumber.dayInLife]?.image_url}
            title={daysInLifeInfo[pageNumber.dayInLife]?.title}
            subtitle={daysInLifeInfo[pageNumber.dayInLife]?.description}
          />
        </div>
        <div className="relative bottom-0 flex justify-between">
          <div
            className="absolute"
            hidden={pageNumber.dayInLife === 0}
            onClick={() => {
              setPageNumber((prevPageNumber: { dayInLife: number }) => ({
                ...prevPageNumber,
                dayInLife: prevPageNumber.dayInLife - 1,
              }));
            }}
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                transform="rotate(-180 17.5 17.5)"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3323 18.3306L20.1311 27.6585C20.5606 28.1138 21.2571 28.1138 21.6866 27.6585C22.1045 27.2032 22.1045 26.4771 21.6866 26.0218L13.6539 17.5062L21.6866 8.99048C22.1045 8.53516 22.1045 7.79681 21.6866 7.34149C21.2571 6.88617 20.5606 6.88617 20.1311 7.34149L11.3207 16.6817C10.8912 17.137 10.8912 17.8753 11.3323 18.3306Z"
                fill="#56686F"
              />
            </svg>
          </div>
          <div className="absolute right-[47%] top-2">
            {pageNumber.dayInLife + 1}/{daysInLifeInfo?.length}
          </div>
          <div
            className="absolute right-0"
            hidden={
              pageNumber.dayInLife === (daysInLifeInfo?.length as number) - 1
            }
            onClick={() => {
              setPageNumber((prevPageNumber: { dayInLife: number }) => ({
                ...prevPageNumber,
                dayInLife: prevPageNumber.dayInLife + 1,
              }));
              checkNextEnable(pageNumber.dayInLife >= 4 ? true : false);
            }}
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6677 16.6694L14.8689 7.34149C14.4394 6.88617 13.7429 6.88617 13.3134 7.34149C12.8955 7.79681 12.8955 8.52285 13.3134 8.97817L21.3461 17.4938L13.3134 26.0095C12.8955 26.4648 12.8955 27.2032 13.3134 27.6585C13.7429 28.1138 14.4394 28.1138 14.8689 27.6585L23.6793 18.3183C24.1088 17.863 24.1088 17.1247 23.6677 16.6694Z"
                fill="#56686F"
              />
            </svg>
          </div>
        </div>
      </GlimpseCard>
    </>
  );
};
export const DidYouKnowCard: React.FC<IDidYouKnowsCard> = ({
  didyouKnowsInfo,
  pageNumber,
  setPageNumber,
  flex,
  from,
  checkNextEnable,
  handleOpen,
}) => {
  useEffect(() => {
    if (window.innerWidth >= 768) {
      checkNextEnable(true); // Default enable next button for desktop view
    }
  }, [window.innerWidth]);

  return (
    <>
      <GlimpseCard
        title="Did You Know?"
        className="hidden lg:flex "
        subtitleClass="text-sm mt-1"
        subtitle={
          from === "DeeperDive"
            ? "We pulled together some interesting facts about this career."
            : ""
        }
      >
        <div
          className={`${
            flex
              ? "justify-between px-[70px] pt-[40px] pb-[20px] flex  "
              : "grid grid-cols-2 justify-items-center "
          } `}
        >
          {didyouKnowsInfo?.map((items, index) => {
            return (
              <GlimpseScrollCard
                key={index}
                className="w-[164px] h-[165px] text-center flex justify-center flex-col"
                image={items.icon_url}
                subtitle={items.title}
              />
            );
          })}
        </div>
        {from === "DeeperDive" && (
          <div className="flex justify-center text-[10px] gap-1">
            <span>View sources </span>
            <span
              className="underline cursor-pointer text-gray"
              onClick={handleOpen}
            >
              here
            </span>
          </div>
        )}
      </GlimpseCard>

      <GlimpseCard
        title="Did You Know?"
        className="flex lg:hidden min-h-[416px] "
        childrenClass="relative"
        subtitle={
          from === "DeeperDive"
            ? "We pulled together some interesting facts about this career."
            : ""
        }
        subtitleClass="text-[15px]"
      >
        <div
          className={`flex items-center justify-center ${
            from === "DeeperDive" ? "h-[280px]" : "h-[320px]"
          }   `}
        >
          <GlimpseScrollCard
            image={didyouKnowsInfo[pageNumber.didyouknow]?.icon_url}
            subtitle={didyouKnowsInfo[pageNumber.didyouknow]?.title}
          />
        </div>
        <div className="relative bottom-0 flex justify-between">
          <div
            className="absolute"
            hidden={pageNumber.didyouknow === 0}
            onClick={() =>
              setPageNumber((prevPageNumber: { didyouknow: number }) => ({
                ...prevPageNumber,
                didyouknow: prevPageNumber.didyouknow - 1,
              }))
            }
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                transform="rotate(-180 17.5 17.5)"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3323 18.3306L20.1311 27.6585C20.5606 28.1138 21.2571 28.1138 21.6866 27.6585C22.1045 27.2032 22.1045 26.4771 21.6866 26.0218L13.6539 17.5062L21.6866 8.99048C22.1045 8.53516 22.1045 7.79681 21.6866 7.34149C21.2571 6.88617 20.5606 6.88617 20.1311 7.34149L11.3207 16.6817C10.8912 17.137 10.8912 17.8753 11.3323 18.3306Z"
                fill="#56686F"
              />
            </svg>
          </div>
          <div className="absolute right-[47%] top-2">
            {pageNumber.didyouknow + 1}/{didyouKnowsInfo?.length}
          </div>
          <div
            className="absolute right-0"
            hidden={
              pageNumber.didyouknow === (didyouKnowsInfo?.length as number) - 1
            }
            onClick={() => {
              setPageNumber((prevPageNumber: { didyouknow: number }) => ({
                ...prevPageNumber,
                didyouknow: prevPageNumber.didyouknow + 1,
              }));
              checkNextEnable(pageNumber.didyouknow >= 2 ? true : false);
            }}
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6677 16.6694L14.8689 7.34149C14.4394 6.88617 13.7429 6.88617 13.3134 7.34149C12.8955 7.79681 12.8955 8.52285 13.3134 8.97817L21.3461 17.4938L13.3134 26.0095C12.8955 26.4648 12.8955 27.2032 13.3134 27.6585C13.7429 28.1138 14.4394 28.1138 14.8689 27.6585L23.6793 18.3183C24.1088 17.863 24.1088 17.1247 23.6677 16.6694Z"
                fill="#56686F"
              />
            </svg>
          </div>
        </div>
        {from === "DeeperDive" && (
          <div className="flex justify-center text-[10px] gap-1 absolute bottom-[-18%] left-[33%]">
            <span>View sources </span>
            <span
              className="underline cursor-pointer text-gray"
              onClick={handleOpen}
            >
              here
            </span>
          </div>
        )}
      </GlimpseCard>
    </>
  );
};
export const ProsConsCard: React.FC<IProsConsCard> = ({
  prosconsInfo,
  deeperDiveClass,
  from,
  pageNumber,
  setPageNumber,
  checkNextEnable,
}) => {
  useEffect(() => {
    if (window.innerWidth >= 768) {
      checkNextEnable(true); // Default enable next button for desktop view
    }
  }, [window.innerWidth]);
  return (
    <>
      <GlimpseCard
        title="Pros and Cons"
        className="items-center hidden lg:flex"
        childrenClass="flex h-full justify-center"
        subtitleClass="text-sm mt-1"
        subtitle={
          from === "DeeperDive"
            ? "Every career comes with good and challenging parts."
            : ""
        }
      >
        <div
          className={`grid grid-cols-1 gap-5 p-4 ${
            !deeperDiveClass ? "xl:grid-cols-2" : ""
          } items-center`}
        >
          {deeperDiveClass ? (
            <div className="flex justify-between w-full gap-5 p-2 overflow-auto h-fit">
              <div
                className={`min-w-[250px] border-r-2 border-horizontalRow p-4 block`}
              >
                <div className="pb-4 text-base font-bold text-green">
                  Common Advantages
                </div>
                {prosconsInfo?.pros.map((items, index) => {
                  return (
                    <div key={index} className="flex flex-row">
                      <ul className="list-disc md:ml-5 list-item">
                        <li>{items.title}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className={`min-w-[250px] p-4 block`}>
                <div className="pb-4 text-base font-bold text-green">
                  Potential Drawbacks
                </div>

                {prosconsInfo?.cons.map((items, index) => {
                  return (
                    <div key={index} className="flex flex-row">
                      <ul className="list-disc md:ml-5 list-item">
                        <li className="appearance-none">{items.title}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              <div className={`xl:border-r-2 border-horizontalRow block pr-4`}>
                <div className="pb-4 text-base font-bold text-green">
                  Common Advantages
                </div>
                {prosconsInfo?.pros.map((items, index) => {
                  return (
                    <div key={index} className="flex flex-row">
                      <ul className="list-disc md:ml-5 list-item">
                        <li>{items.title}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className={`block`}>
                <div className="pb-4 text-base font-bold text-green">
                  Potential Drawbacks
                </div>
                {prosconsInfo?.cons.map((items, index) => {
                  return (
                    <div key={index} className="flex flex-row">
                      <ul className="list-disc md:ml-5 list-item">
                        <li>{items.title}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </GlimpseCard>

      <GlimpseCard
        title="Pros and Cons"
        className="flex lg:hidden min-h-[416px] "
        childrenClass="relative"
        subtitleClass="text-[14px]"
        subtitle={
          from === "DeeperDive"
            ? "Every career comes with good and challenging parts."
            : ""
        }
      >
        <div
          className={`flex items-center justify-center ${
            from === "DeeperDive" ? "h-[280px]" : "h-[320px]"
          }   `}
        >
          {pageNumber && pageNumber?.prosCons === 0 ? (
            <div className={`min-w-[250px] p-4 block`}>
              <div className="pb-4 text-base font-bold text-green">
                Common Advantages
              </div>
              {prosconsInfo?.pros.map((items, index) => {
                return (
                  <div key={index} className="flex flex-row">
                    <ul className="list-disc md:ml-5 list-item">
                      <li>{items.title}</li>
                    </ul>
                  </div>
                );
              })}
            </div>
          ) : null}
          {pageNumber && pageNumber?.prosCons === 1 ? (
            <div className={`min-w-[250px] p-4 block`}>
              <div className="pb-4 text-base font-bold text-green">
                Potential Drawbacks
              </div>
              {prosconsInfo?.cons.map((items, index) => {
                return (
                  <div key={index} className="flex flex-row">
                    <ul className="list-disc md:ml-5 list-item">
                      <li>{items.title}</li>
                    </ul>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="relative bottom-0 flex justify-between">
          <div
            className="absolute"
            hidden={pageNumber?.prosCons === 0}
            onClick={() =>
              setPageNumber((prevPageNumber: { prosCons: number }) => ({
                ...prevPageNumber,
                prosCons: prevPageNumber.prosCons - 1,
              }))
            }
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                transform="rotate(-180 17.5 17.5)"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3323 18.3306L20.1311 27.6585C20.5606 28.1138 21.2571 28.1138 21.6866 27.6585C22.1045 27.2032 22.1045 26.4771 21.6866 26.0218L13.6539 17.5062L21.6866 8.99048C22.1045 8.53516 22.1045 7.79681 21.6866 7.34149C21.2571 6.88617 20.5606 6.88617 20.1311 7.34149L11.3207 16.6817C10.8912 17.137 10.8912 17.8753 11.3323 18.3306Z"
                fill="#56686F"
              />
            </svg>
          </div>
          <div className="absolute right-[47%] top-2">
            {pageNumber?.prosCons ? pageNumber?.prosCons + 1 : 1}/2
          </div>
          <div
            className="absolute right-0"
            hidden={pageNumber?.prosCons === 1}
            onClick={() => {
              setPageNumber((prevPageNumber: { prosCons: number }) => ({
                ...prevPageNumber,
                prosCons: prevPageNumber.prosCons + 1,
              }));
              checkNextEnable(true);
            }}
          >
            <svg
              width={35}
              height={35}
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17.5"
                r="16.5"
                stroke="#56686F"
                strokeWidth={2}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6677 16.6694L14.8689 7.34149C14.4394 6.88617 13.7429 6.88617 13.3134 7.34149C12.8955 7.79681 12.8955 8.52285 13.3134 8.97817L21.3461 17.4938L13.3134 26.0095C12.8955 26.4648 12.8955 27.2032 13.3134 27.6585C13.7429 28.1138 14.4394 28.1138 14.8689 27.6585L23.6793 18.3183C24.1088 17.863 24.1088 17.1247 23.6677 16.6694Z"
                fill="#56686F"
              />
            </svg>
          </div>
        </div>
      </GlimpseCard>
    </>
  );
};
export const SalaryCard: React.FC<ISalary> = ({
  advance,
  median,
  starting,
  from,
  handleOpen,
}) => {
  const salaryLabel = [
    { class: "Low", subclass: "Starting" },
    { class: "Median", subclass: "" },
    { class: "High", subclass: "Experienced" },
  ];
  const label = salaryLabel?.map((i: any) => {
    return [i.class, i.subclass];
  });

  const dataSet = {
    datasets: [
      {
        label: "Salary",
        data: [starting, median, advance],
        backgroundColor: ["#D4B07D", "#F4CC94", "#FFD497"],
        borderRadius: { topLeft: 8, topRight: 8 },
      },
    ],
    // labels: ["Low", "Median", "High"],
    labels: label,
  };

  return (
    <GlimpseCard
      title="Salary"
      childrenClass="flex justify-center h-full relative"
      subtitleClass="text-[14px] sm:text-sm "
      subtitle={
        from === "DeeperDive"
          ? "These are average salary ranges in the U.S. These can vary based on experience and location."
          : ""
      }
    >
      <div
        className={`flex items-center justify-center xl:pb-[20px] ${
          from === "DeeperDive" && "h-[290px]"
        }`}
      >
        <BarChart data={dataSet} key={"salaryChart"} salaryChart={true} />
      </div>
      {from === "DeeperDive" && (
        <div className="flex justify-center text-[10px] gap-1 absolute bottom-0 ">
          <span>View sources </span>
          <span
            className="underline cursor-pointer text-gray"
            onClick={handleOpen}
          >
            here
          </span>
        </div>
      )}
    </GlimpseCard>
  );
};

export const CareerTrendCard: React.FC<ICareerTrendCard> = ({
  careerTrendInfo,
  deeperDiveClass,
  from,
  handleOpen,
}) => {
  return (
    <GlimpseCard
      title="Career Trends"
      subtitle={
        from === "DeeperDive"
          ? "Understand current trends in the job market for this career."
          : ""
      }
      subtitleClass="text-[14px] sm:text-sm mt-1"
      childrenClass={` ${
        deeperDiveClass
          ? "flex flex-col md:flex-row justify-center items-center gap-4 md:justify-around h-full p-[10px] relative md:items-start md:pt-[100px]"
          : " flex flex-col md:flex-row xl:flex-col justify-center items-center gap-4 md:justify-around h-full p-[10px] relative md:items-start md:pt-[100px] xl:p-[50px]"
      }`}
    >
      {careerTrendInfo?.map((items, index) => {
        return (
          <div key={index}>
            <div className="text-center ">
              <GlimpseCareerTrends
                className="flex flex-col items-start"
                title={items.title}
                subtitle={items.description}
              />
            </div>
          </div>
        );
      })}
      {from === "DeeperDive" && (
        <div className="text-[10px] absolute bottom-0">
          <span>View sources </span>
          <span
            className="underline cursor-pointer text-gray"
            onClick={handleOpen}
          >
            here
          </span>
        </div>
      )}
    </GlimpseCard>
  );
};
export const ImpactCard: React.FC<IImpactCard> = ({
  impactInfo,
  from,
  handleOpen,
}) => {
  return (
    <GlimpseCard
      title="Impact on Others"
      subtitle={from === "DeeperDive" ? "Why this career matters." : ""}
      childrenClass="h-full"
      subtitleClass="text-sm mt-1"
      className="h-auto"
    >
      <div className="flex flex-col items-center justify-between h-full">
        <div className="flex flex-col justify-center gap-8 h-[90%] max-w-[500px]">
          {impactInfo.map((item, index) => {
            return (
              <GlimpseImpactCard
                key={index}
                impact={item.description}
                author={item.author}
              />
            );
          })}
        </div>
        {from === "DeeperDive" && (
          <div className="text-[10px]">
            <span>View sources </span>
            <span
              className="underline cursor-pointer text-gray"
              onClick={handleOpen}
            >
              here
            </span>
          </div>
        )}
      </div>
    </GlimpseCard>
  );
};
export const Reprioritise: React.FC<IReprioritise> = ({ onNo, onYes }) => {
  return (
    <GlimpseCard
      title="Reprioritize"
      subtitleClass="text-sm mt-1"
      subtitle="After learning about this career, do you want to reprioritize its ranking?"
    >
      <div className="flex flex-col items-center gap-2 py-20">
        <div
          onClick={onYes}
          className="px-3 py-3 w-[126px] h-[48px] font-bold text-white   text-center rounded-full bg-green cursor-pointer"
        >
          Yes
        </div>
        <div
          onClick={() => onNo(false)}
          className="px-3 py-3 w-[126px] h-[48px] text-red font-bold rounded-full text-center bg-white border border-red cursor-pointer"
        >
          NO
        </div>
      </div>
    </GlimpseCard>
  );
};
export const GreatWork: React.FC<IGreatWork> = ({
  isQuizEnable,
  careerName,
}) => {
  return (
    <GlimpseCard>
      <div className="flex flex-col items-center gap-2 py-20 font-bold text-headerBlue text-[36px]">
        Great Work!
        <div className="text-sm font-normal text-center max-w-[500px]">
          {isQuizEnable
            ? `Let’s review. Answer the following 3 questions correctly to complete
          the ${careerName} Deep Dive.`
            : `You've finished going through the module.`}
        </div>
      </div>
    </GlimpseCard>
  );
};
export const Quizzes: React.FC<IDeeperDiveQuiz> = ({
  question,
  answers,
  currentSlide,
  answerChoosed,
}) => {
  const [hasError, setHasError] = useState<boolean | null>(null);

  return (
    <GlimpseCard title={`Question ${currentSlide}`}>
      {question && (
        <>
          {hasError || hasError === null ? (
            <div className="flex flex-col gap-2 py-20 text-xl font-bold text-headerBlue ">
              {question}
              <div className="text-sm font-normal text-left">
                {answers.map((items: any, index: number) => {
                  return (
                    <ul key={index}>
                      <li
                        className={`p-2 m-2 border cursor-pointer rounded-xl itemsborder-gray`}
                      >
                        <input
                          type="radio"
                          aria-label="is_Correct"
                          id={question}
                          name={question}
                          // checked={items.is_correct}
                          className="checked:bg-green hover:checked:bg-green"
                          onChange={(ev) => {}}
                          onClick={() => {
                            setHasError(!items.is_correct);
                            answerChoosed(items);
                          }}
                        />
                        <span className="pl-2"> {items.answer}</span>
                      </li>
                    </ul>
                  );
                })}
                {hasError && (
                  <span className="pl-4 text-red">
                    That is the incorrect answer. Please try again.
                  </span>
                )}
              </div>
            </div>
          ) : (
            <>
              {currentSlide === 1 ? (
                <div className="flex flex-col items-center gap-2 py-20 font-bold text-headerBlue text-[36px]">
                  Excellent!
                  <div className="text-sm font-normal text-center">
                    You really know your stuff.
                  </div>
                  <div>
                    <svg
                      width={90}
                      height={90}
                      viewBox="0 0 90 90"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx={45} cy={45} r={45} fill="#529C82" />
                      <circle
                        cx="44.5881"
                        cy="44.5871"
                        r="36.3303"
                        fill="#6CBEA1"
                      />
                      <line
                        x1="26.8841"
                        y1="48.7158"
                        x2="35.5055"
                        y2="57.3372"
                        stroke="#F4EDDB"
                        strokeWidth={17}
                        strokeLinecap="round"
                      />
                      <line
                        x1="63.5781"
                        y1="33.4886"
                        x2="37.6173"
                        y2="59.4494"
                        stroke="#F4EDDB"
                        strokeWidth={17}
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              ) : currentSlide === 2 ? (
                <div className="flex flex-col items-center gap-2 py-20 font-bold text-headerBlue text-[36px]">
                  Nice Work!
                  <div className="text-sm font-normal text-center">
                    It's almost like you're already a pro.
                  </div>
                  <div>
                    <svg
                      width={90}
                      height={90}
                      viewBox="0 0 90 90"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx={45} cy={45} r={45} fill="#529C82" />
                      <circle
                        cx="44.5881"
                        cy="44.5871"
                        r="36.3303"
                        fill="#6CBEA1"
                      />
                      <line
                        x1="26.8841"
                        y1="48.7158"
                        x2="35.5055"
                        y2="57.3372"
                        stroke="#F4EDDB"
                        strokeWidth={17}
                        strokeLinecap="round"
                      />
                      <line
                        x1="63.5781"
                        y1="33.4886"
                        x2="37.6173"
                        y2="59.4494"
                        stroke="#F4EDDB"
                        strokeWidth={17}
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-2 py-20 font-bold text-headerBlue text-[36px]">
                  Congrats!
                  <div className="text-sm font-normal text-center">
                    You got all three correct.
                  </div>
                  <div>
                    <svg
                      width={90}
                      height={90}
                      viewBox="0 0 90 90"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx={45} cy={45} r={45} fill="#529C82" />
                      <circle
                        cx="44.5881"
                        cy="44.5871"
                        r="36.3303"
                        fill="#6CBEA1"
                      />
                      <line
                        x1="26.8841"
                        y1="48.7158"
                        x2="35.5055"
                        y2="57.3372"
                        stroke="#F4EDDB"
                        strokeWidth={17}
                        strokeLinecap="round"
                      />
                      <line
                        x1="63.5781"
                        y1="33.4886"
                        x2="37.6173"
                        y2="59.4494"
                        stroke="#F4EDDB"
                        strokeWidth={17}
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </GlimpseCard>
  );
};
