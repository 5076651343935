import { useMutation, useQuery } from "@tanstack/react-query";
import { displayWarning } from "components/Toast/toast.component";
import GlimpseHeader from "components/career-header/career-header.component";
import Loading from "components/loading/loading.component";
import { getIntroVideo, unlockOnboard } from "services/on-board";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "components/video-streaming-onboard/video-player.component";
import ReactPlayer from "react-player";
import { useRef, useState } from "react";

const UserOnBoard: React.FC = () => {
  const navigate = useNavigate();
  const unlockOnBoard = useMutation({
    mutationKey: ["StartOnBoard"],
    mutationFn: () => unlockOnboard(),
    retry: 0,
    onSuccess: async () => {
      navigate("/on-boarding/start");
    },
    onError: (err: Error) => {
      console.error(err);
      displayWarning("Unable to unlock careers");
    },
  });

  const { data: instructionVideoInfo, isLoading } = useQuery({
    queryKey: ["getInstructionVideo"],
    queryFn: () => getIntroVideo(),
    refetchOnWindowFocus: false,
    retry: 0,
    
  });


  const isUnlocking = unlockOnBoard.isPending;

  const startUnlock = () => {
    unlockOnBoard.mutateAsync();
  };
const [playing,setPlaying]=useState(false)

const videoRef=useRef<ReactPlayer>(null)

  return (
    <>
      <div className="relative flex content-center justify-center w-full h-[90vh] px-8">
        <div className="flex flex-col justify-center">
          <>
            <GlimpseHeader
              title="Welcome to Glimpse"
              subtitle="Catch a glimpse of your future"
              titleClass="text-[24px] lg:text-4xl"
              subTitleClass="text-[20px]  lg:text-2xl"
              image="/assets/images/GlimpseLogoMark.png"
            >


{/* 
<div className="rounded-lg  mb-3">
                    <ReactPlayer
                  ref={videoRef}
                  playing={playing}
                  // config={{ file: { attributes: {
                  //   autoPlay: true,
                  //   muted: true,
                    
                  //   }}}}
                  url={instructionVideoInfo ? instructionVideoInfo[0]?.video_url as string : ' '}
                  light={'/assets/images/videoThumbnail.webp' as string}
                  videoStatus="completed"
                  onStart={() => { }}
                  onReady={()=>setPlaying(true)}
                  onBuffer={() => { } }
                  onEnd={() => { } }
                  onProgress={(data) => { } }
                  onPlay={()=>{}}
                  onPaused={() => { } }
                  onSeek={() => { } }
                  
                  className=" !w-full !h-[341px] xl:items-center xl:justify-center xl:flex xl:!w-full xl:!h-[270px]" 
                  />
                  </div> */}






              
              <div className="flex flex-col gap-4">
                <span>
                  We're so excited that you are on a journey to increase your
                  Career Awareness. You will unlock possibilities and discover
                  purpose as you progress through Glimpse.
                </span>
                <span>What to expect as you get started:</span>
                <ul className="list-decimal list-inside">
                  <li className="mb-2 list-item">
                    Watch a series of 5 career videos. You need to watch each
                    video in full before you can rank that career.
                  </li>
                  <li className="mb-2 list-item">
                    Sort each career card into one of three categories based on
                    interest level.
                  </li>
                  <li className="mb-2 list-item">
                    Enter the Glimpse platform to watch more career videos, take
                    deeper dives, and ultimately find your Top 3 careers.
                  </li>
                </ul>
                <span>Let’s get started!</span>
              </div>
            </GlimpseHeader>
            <button
              type="submit"
              name="save"
              onClick={() => startUnlock()}
              className="mt-[22px] text-center text-white bg-green border border-green gap-3 pl-1.5 pt-2.5 pr-1.5 pb-2.5 w-[121px] rounded-full text-sm font-normal leading-5"
            >
              {isUnlocking ? <Loading /> : "Start"}
            </button>
          </>
        </div>
      </div>
    </>
  );
};

export default UserOnBoard;
