import GlimpseHeader from "components/career-header/career-header.component";
import { IVideoPlayer } from "interfaces/VideoStreamingInterface";
import React, { useEffect, useRef } from "react";
import VideoPlayer from "./video-player.component";
import ReactPlayer from "react-player";

const VideoStreaming: React.FC<IVideoPlayer> = ({
  header,
  video_url,
  className,
  
  onEnd,
  onPaused,
  onProgress,
  onSeek,
  onStart,
  progress,
  thumbnail,
  videoStatus,
  onBuffer,
  popupClass,
  handleSortNow,
  handleNext,
  handleBack,
  enableNext,
  currentSlide,
  enableButtons,
  currentIndex,
}) => {
  const videoRef=useRef<ReactPlayer>(null)



useEffect(() => {
console.log(currentIndex,currentSlide)
if(videoRef){  const player=   videoRef?.current?.getInternalPlayer()
  player?.pause()}

 
}, [currentSlide])


  return (
    <div className="max-w-[297px] h-full ">
      <GlimpseHeader
        title={header?.title as string}
        subtitle={header?.subtitle as string}
        image={header?.image as string}
      >
        {header?.children}
      </GlimpseHeader>
      <div
        className={`flex justify-center w-max min-h-[375px] min-w-[212px] ${popupClass} `}
      >
        <VideoPlayer
        videoRef={videoRef}
          key={video_url}
          video_url={video_url}
          onBuffer={onBuffer}
          onStart={onStart}
          onEnd={onEnd}
          onProgress={onProgress}
          onPaused={onPaused}
          thumbnail={thumbnail}
          onSeek={onSeek}
          className="h-full"
          videoStatus={videoStatus}
        />

        <div key={currentSlide}>
          {enableButtons && currentIndex === currentSlide && (
            <div className="flex-col justify-end hidden gap-2 sm:flex w-[110px] absolute bottom-2 left-[270px]">
              <button
                onClick={handleBack}
                className={`px-2 py-1 bg-white border rounded-full text-green border-green ${currentSlide && currentSlide >= 1 ? "" : "hidden"
                  }`}
              >
                Back
              </button>

              <button
                disabled={!enableNext}
                onClick={handleSortNow}
                className={`px-2 py-1 text-white rounded-full bg-green ${!enableNext
                  ? " cursor-not-allowed opacity-40"
                  : " cursor-pointer"
                  }`}
              >
                Sort Now
              </button>
              <button
                disabled={!enableNext}
                onClick={() => 
{
                 if( handleNext) { 
               const player=   videoRef?.current?.getInternalPlayer()
player?.pause()
                  handleNext(false) }}
                
                }
                className={`px-2 py-1 text-white rounded-full bg-green ${!enableNext
                  ? " cursor-not-allowed opacity-40"
                  : " cursor-pointer"
                  }`}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoStreaming;
