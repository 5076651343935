import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading/loading.component";
import GlimpseHeader from "../../components/career-header/career-header.component";
import { useEffect, useRef, useState } from "react";
import { archiveCareer, getCareerById } from "services/career.service";
import GlimpsePopup from "components/popup/popup.component";
import {
  CareerTrendCard,
  ContributionCard,
  DayinLifeCard,
  DidYouKnowCard,
  EducationCard,
  ExpertVoices,
  ImpactCard,
  ProsConsCard,
  SalaryCard,
} from "components/career-details/career-details.component";
import {
  CareerTrends,
  DayinLife,
  DidYouKnow,
  Education,
  Impacts,
  PageNumberState,
  ProsCons,
} from "interfaces/CareerInfoInterface";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { useSelector } from "react-redux";
import {
  CareerDetailsInfo,
  CareerInfo,
  IDeeperDiveEducations,
  IDeeperDiveProsCons,
  IExpertVoices,
  IExpertVoicesString,
} from "interfaces/CareerInterface";
import VideoPlayer from "components/video-streaming-onboard/video-player.component";
import GlimpseCard from "components/card/card.component";
import { References } from "enums/glimps.enums";
import ReactPlayer from "react-player";

const CareerInfoScreen: React.FC = () => {
  let { param } = useParams();


  const videoRef = useRef<ReactPlayer>(null)
  const { userInfo } = useSelector((state: any) => state.auth);
  const [selectedVideo, setSelectedVideo] = useState<IExpertVoicesString>();
  const [enableDayInLife, setEnableDayInLife] = useState<boolean>(false);
  const [enableDidYouKnow, setEnableDidYouKnow] = useState<boolean>(false);
  const [enableEducation, setEnableEducation] = useState<boolean>(false);
  const [enableProsCons, setEnableProsCons] = useState<boolean>(false);
  let navigate = useNavigate();
  const [popup, setPopup] = useState<boolean>(false);
  const [archiveAlert, setArchiveAlert] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<PageNumberState>({
    dayInLife: 0,
    didyouknow: 0,
    education: 0,
    prosCons: 0,
  });

  const { data: careerInfo, isLoading } = useQuery({
    queryKey: ["GetCareerById", param],
    queryFn: () => getCareerById(param as string),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const handleArchiveCareer = useMutation({
    mutationFn: (data: CareerDetailsInfo) => archiveCareer(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Career has been archived successfully.");
    },
    onError: () => {
      displayError("Unable to archive career.");
    },
  });

  const handleArchiveCareerSubmit = (data: CareerDetailsInfo) => {
    handleArchiveCareer.mutateAsync(data);
  };

  useEffect(() => {
    if (careerInfo?.expert_voices.length) {
      setSelectedVideo(careerInfo?.expert_voices[0]);
    }
  }, [careerInfo?.expert_voices]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : careerInfo ? (
        <div className="flex flex-col flex-wrap w-full gap-5  md:px-[16px] max-w-[983px]">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[80px] h-[35px] rounded-full text-sm font-normal leading-5"
          >
            <div className="flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="12"
                viewBox="0 0 12 8"
                fill="none"
              >
                <path
                  d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
                  fill="black"
                />
              </svg>
              <span className="pl-2 font-semibold">Back</span>
            </div>
          </button>
          {careerInfo && (
            <GlimpseHeader
              title={careerInfo?.name ? careerInfo.name : "Name"}
              subtitle={
                careerInfo?.superpower ? careerInfo.superpower : "SuperPower"
              }
              image={
                careerInfo?.icon_url
                  ? careerInfo?.icon_url
                  : "/assets/images/NoPreview.jpg"
              }
              userRole={userInfo.user.role}
              careerStatus={careerInfo.status}
              editCareer={() => navigate(`/careers/details/${param as string}`)}
              setArchiveAlert={setArchiveAlert}
            >
              <span>{careerInfo?.description}</span>
            </GlimpseHeader>
          )}

          <GlimpsePopup
            title=""
            onClose={() => {
              setArchiveAlert(false);
            }}
            showCross={false}
            popup={archiveAlert}
            customClass="max-w-[347px]"
          >
            <div className="text-headerBlue text-[20px] flex items-start">
              Archive
              <span className="px-1 font-bold"> {careerInfo.name}?</span>
            </div>
            <div>
              New users are not able to see archived careers. Already existing
              users can still see and interact with it on their dashboard. All
              career data will be preserved.
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setArchiveAlert(false)}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              <button
                className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                onClick={() => {
                  handleArchiveCareerSubmit(careerInfo);
                  setArchiveAlert(false);
                  navigate(-1);
                }}
              >
                Confirm
              </button>
            </div>
          </GlimpsePopup>

          <div className="grid w-full gap-5 sm:grid-cols-1 sm:w-full xl:flex xl:flex-row">
            <div className="min-w-[343px] h-[385px] xl:min-w-[378px] ">
              <ContributionCard
                contribution_url={careerInfo?.contribution_url}
              />
            </div>
            <div className="min-w-[343px] xl:min-w-[266px] h-[440px] xl:h-[385px]">
              <GlimpseCard title="Expert Voices" childrenClass="h-full">
                <div className="flex items-center justify-center h-full gap-4 ">
                  {careerInfo.expert_voices.length > 1 && (
                    <div className="flex flex-col justify-center ">
                      {careerInfo.expert_voices.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={` ${
                              selectedVideo?.voice_url === item.voice_url
                                ? "opacity-1 border-2 border-headerBlue p-1"
                                : "opacity-[0.5] p-1"
                            }  block mb-1 rounded-md `}
                          >
                            <img
                              src={
                                item.voice_thumbnail
                                  ? item.voice_thumbnail
                                  : "/assets/images/NoPreview.jpg"
                              }
                              alt="Contribution"
                              className="max-w-[62px] max-h-[110px] md:max-h-[82px]"
                              onClick={() => setSelectedVideo(item)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="rounded-lg">
                    <VideoPlayer
                    videoRef={videoRef}
                      video_url={selectedVideo?.voice_url as string}
                      thumbnail={selectedVideo?.voice_thumbnail as string}
                      videoStatus="completed"
                      onStart={() => {}}
                      onBuffer={() => {}}
                      onEnd={() => {}}
                      onProgress={(data) => {}}
                      onPaused={() => {}}
                      onSeek={() => {}}
                      className=" !w-[193px] !h-[341px] xl:items-center xl:justify-center xl:flex xl:!w-[150px] xl:!h-[270px]"
                    />
                  </div>
                </div>
              </GlimpseCard>
            </div>
            <div className="min-w-[343px] h-[385px] xl:min-w-[301px] hidden xl:flex ">
              <EducationCard
                checkNextEnable={setEnableEducation}
                education={
                  careerInfo?.educations
                    ? (careerInfo.educations as IDeeperDiveEducations[])
                    : []
                }
              />
            </div>
          </div>
          <div className="impacts min-w-[343px] min-h-[210px] md:max-w-[663px] md:min-h-[271px] order-last p-1">
            <ImpactCard
              impactInfo={
                (careerInfo?.impacts ? careerInfo.impacts : []) as Impacts[]
              }
            />
            <div className="sources text-[10px] pt-4">
              <span>View sources </span>
              <span
                className="underline cursor-pointer text-headerBlue"
                onClick={() => {
                  setPopup(true);
                }}
              >
                here.
              </span>
              {popup && (
                <div className="relative flex items-center justify-center bg-red">
                  <GlimpsePopup
                    title="Sources"
                    popup={popup}
                    onClose={() => {
                      setPopup(false);
                    }}
                    showCross={true}
                    customClass="max-w-[388px]"
                  >
                    <>
                      <div>
                        <div className="mb-6">
                          <p className="text-2xl font-bold text-[#10446A]">
                            {careerInfo.name}: Sources
                          </p>
                          <p className="text-base font-normal">
                            Listed below are sources we used to gather data
                            about this career.
                          </p>
                        </div>
                        <div className="mb-3">
                          {careerInfo.references.filter(
                            (x) => x.title === References.DidYouKnow && x.link
                          ).length > 0 && (
                            <>
                              {" "}
                              <p className="text-xl font-normal">
                                Did You Know
                              </p>
                              <ol type="1">
                                {careerInfo.references
                                  .filter(
                                    (x) => x.title === References.DidYouKnow
                                  )
                                  .map((item) => (
                                    <li>
                                      <a
                                        className="text-base font-normal"
                                        href={item.link as string}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.link}
                                      </a>
                                    </li>
                                  ))}
                              </ol>
                            </>
                          )}
                        </div>
                        <div className="mb-3">
                          {careerInfo.references.filter(
                            (x) => x.title === References.Salary && x.link
                          ).length > 0 && (
                            <>
                              {" "}
                              <p className="text-xl font-normal">Salary</p>
                              <ol type="1">
                                {careerInfo.references
                                  .filter((x) => x.title === References.Salary)
                                  .map((item) => (
                                    <li>
                                      <a
                                        className="text-base font-normal"
                                        href={item.link as string}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.link}
                                      </a>
                                    </li>
                                  ))}
                              </ol>
                            </>
                          )}
                        </div>
                        <div className="mb-3">
                          {careerInfo.references.filter(
                            (x) => x.title === References.CareerDemand && x.link
                          ).length > 0 && (
                            <>
                              {" "}
                              <p className="text-xl font-normal">
                                Career Demand
                              </p>
                              <ol type="1">
                                {careerInfo.references
                                  .filter(
                                    (x) => x.title === References.CareerDemand
                                  )
                                  .map((item) => (
                                    <li>
                                      <a
                                        className="text-base font-normal"
                                        href={item.link as string}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.link}
                                      </a>
                                    </li>
                                  ))}
                              </ol>
                            </>
                          )}
                        </div>
                        <div className="mb-3">
                          {careerInfo.references.filter(
                            (x) => x.title === References.ImpactQuote && x.link
                          ).length > 0 && (
                            <>
                              <p className="text-xl font-normal">
                                Impact Quote
                              </p>
                              <ol type="1">
                                {careerInfo.references
                                  .filter(
                                    (x) => x.title === References.ImpactQuote
                                  )
                                  .map((item) => (
                                    <li>
                                      <a
                                        className="text-base font-normal"
                                        href={item.link as string}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.link}
                                      </a>
                                    </li>
                                  ))}
                              </ol>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  </GlimpsePopup>
                </div>
              )}
            </div>
          </div>
          <div className="hidden gap-5 w-[983px] xl:flex ">
            <div className="flex flex-col gap-5 max-h-[594px]">
              <div className="min-w-[663px] min-h-[285px] relative">
                <DayinLifeCard
                  checkNextEnable={setEnableDayInLife}
                  daysInLifeInfo={
                    (careerInfo?.days_in_life
                      ? careerInfo.days_in_life
                      : []) as DayinLife[]
                  }
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
              <div className="min-w-[663px] h-[305px] items-center order-3">
                <ProsConsCard
                  prosconsInfo={careerInfo?.pros_cons as IDeeperDiveProsCons}
                  deeperDiveClass={false}
                  checkNextEnable={setEnableProsCons}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5 max-h-[594px]">
              <div className="w-[301px] h-[230px]">
                <SalaryCard
                  advance={
                    careerInfo.salaries.advance
                      ? Number(careerInfo.salaries.advance)
                      : 0
                  }
                  median={
                    careerInfo.salaries.median
                      ? Number(careerInfo.salaries.median)
                      : 0
                  }
                  starting={
                    careerInfo.salaries.starting
                      ? Number(careerInfo.salaries.starting)
                      : 0
                  }
                />
              </div>
              <div className="w-[301px] h-[358px] ">
                <CareerTrendCard
                  careerTrendInfo={
                    (careerInfo?.trends
                      ? careerInfo.trends
                      : []) as CareerTrends[]
                  }
                  deeperDiveClass={false}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 xl:hidden ">
            <div className="min-w-[343px] h-[416px] order-1">
              <DayinLifeCard
                checkNextEnable={setEnableDayInLife}
                daysInLifeInfo={
                  (careerInfo?.days_in_life
                    ? careerInfo.days_in_life
                    : []) as DayinLife[]
                }
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </div>{" "}
            <div className="min-w-[343px] items-center order-2">
              <ProsConsCard
                prosconsInfo={careerInfo?.pros_cons as IDeeperDiveProsCons}
                deeperDiveClass={false}
                checkNextEnable={setEnableProsCons}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </div>
            <div className="min-w-[340px] h-[416px] order-3">
              <DidYouKnowCard
                checkNextEnable={setEnableDidYouKnow}
                didyouKnowsInfo={
                  (careerInfo?.did_you_knows
                    ? careerInfo.did_you_knows
                    : []) as DidYouKnow[]
                }
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </div>
            <div className="min-w-[343px] h-[385px] xl:min-w-[301px] order-4 ">
              <EducationCard
                checkNextEnable={setEnableEducation}
                education={
                  careerInfo?.educations
                    ? (careerInfo.educations as IDeeperDiveEducations[])
                    : []
                }
              />
            </div>
            <div className="min-w-[343px] order-5 ">
              <SalaryCard
                advance={
                  careerInfo.salaries.advance
                    ? Number(careerInfo.salaries.advance)
                    : 0
                }
                median={
                  careerInfo.salaries.median
                    ? Number(careerInfo.salaries.median)
                    : 0
                }
                starting={
                  careerInfo.salaries.starting
                    ? Number(careerInfo.salaries.starting)
                    : 0
                }
              />
            </div>
            <div className="min-w-[343px] h-[360px] order-5">
              <CareerTrendCard
                careerTrendInfo={
                  (careerInfo?.trends
                    ? careerInfo.trends
                    : []) as CareerTrends[]
                }
                deeperDiveClass={false}
              />
            </div>
          </div>
          <div className="hidden gap-5 xl:flex didyouknow-xl">
            <div className="min-w-full min-h-[271px] flex flex-col">
              <DidYouKnowCard
                checkNextEnable={setEnableDidYouKnow}
                didyouKnowsInfo={
                  (careerInfo?.did_you_knows
                    ? careerInfo.did_you_knows
                    : []) as DidYouKnow[]
                }
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                flex={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <Loading text="Career Not found" />
      )}
    </div>
  );
};

export default CareerInfoScreen;
