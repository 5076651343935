export function getPageTitle(pathname, role) {
  if (role === "super") {
    if (pathname.includes("admin-dashboard")) {
      return "Super Admin - Overview";
    }
    if (pathname.includes("media-content")) {
      return "Super Admin - Medias";
    }
    if (pathname.includes("promo-codes")) {
      return "Super Admin - Promo Codes";
    }
    if (pathname.includes("partners/partners-insight")) {
      return "Super Admin - Partner Account Insight";
    }
    if (pathname.includes("partners")) {
      return "Super Admin - Partners";
    }
    if (pathname.includes("individuals")) {
      return "Super Admin - Individual Memberships";
    }
    if (pathname.includes("careers/career-insights")) {
      return "Closer Look";
    }
    if (pathname.includes("careers/details")) {
      return "Create Career";
    }
    if (pathname.includes("careers")) {
      return "Careers";
    }
    if (pathname.includes("settings")) {
      return "Settings";
    }
    return "";
  } else if (role === "partner") {
    if (pathname.includes("partner-dashboard/individual-insight")) {
      return "User Insight";
    }
    if (pathname.includes("partner-dashboard")) {
      return "Dashboard";
    }
    if (pathname.includes("partner-careers/careers/career-insights")) {
      return "Closer Look";
    }
    if (pathname.includes("partner-careers")) {
      return "Careers";
    }
    if (pathname.includes("coaches-corner")) {
      return "Coaches Corner";
    }
    if (pathname.includes("settings")) {
      return "Settings";
    }
    if (pathname.includes("help-center")) {
      return "Help Center";
    }
    return "";
  } else if (role === "user") {
    if (pathname.includes("user-careers/deeper-dive")) {
      return "Deeper Dive";
    }
    if (pathname.includes("user-careers/careers/career-insights")) {
      return "Closer Look";
    }

    if (pathname.includes("user-careers/unlock-next")) {
      return "Discover More Careers";
    }
    if (pathname.includes("user-careers")) {
      return "Careers";
    }
    if (pathname.includes("prioritize/unlock-next")) {
      return "Discover More Careers";
    }
    if (pathname.includes("prioritize/reprioritize")) {
      return "Reprioritize";
    }
    if (pathname.includes("prioritize")) {
      return "Prioritize";
    }
    if (pathname.includes("career-awareness")) {
      return "Career Awareness";
    }
    if (pathname.includes("settings")) {
      return "Settings";
    }
    if (pathname.includes("help-center")) {
      return "Help Center";
    }

    return "";
  } else {
    return "";
  }
}
