import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { useState } from "react";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { ICareerListItem, ICareerPrioritize } from "interfaces/CareerInterface";
import { DragableCareerItem } from "./drag-drop/dragable-career-item";
import { SortableCareerItem } from "./drag-drop/sortable-item";
import { Grid } from "./drag-drop/drag-drop-grid";
import { useMutation } from "@tanstack/react-query";
import { prioritizeCareer } from "services/career.service";
import { displaySuccess } from "components/Toast/toast.component";
interface ICareerDragDrop {
  datas: ICareerListItem[];
  onClick: (data: ICareerListItem) => void;
}
const CareerDragDrop: React.FC<ICareerDragDrop> = ({ datas, onClick }) => {
  const [items, setItems] = useState<ICareerListItem[]>(datas);
  const [activeId, setActiveId] = useState<any | null>(null);


  const [tempComparer, setTempComparer] = useState<any | null>(null);






  const prioritize = useMutation({
    mutationKey: ["PrioritizeCareer"],
    mutationFn: (data: ICareerPrioritize) => prioritizeCareer(data),
    retry: 0,
    onSuccess: (data: any) => {
      setActiveId(null);

      displaySuccess(data.message);
    },
  });
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;
    const oldIndex = items.indexOf(active.id);
    const newIndex = items.indexOf(over.id);
    let temp = arrayMove(items, oldIndex, newIndex);
    console.log(JSON.stringify(items) === JSON.stringify(datas), 'array comp')

    if ((JSON.stringify(items) === JSON.stringify(temp)) || (active.id.id === over.id.id)) {

      if (JSON?.stringify(items) === JSON?.stringify(temp)) {
        setActiveId(null);
        return null
      }

      setActiveId(null);

    }
    else if (active.id.id !== over.id.id) {



      if (JSON?.stringify(datas) === JSON?.stringify(temp)) {
        setActiveId(null);
        return null
      }




      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    } else {

      if (items?.toString() == datas?.toString()) {
        console.log(items, datas, 'same list')
        setActiveId(null);

      } else {
        setActiveId(null);

      }



    }

  }

  function handleDragCancel() {
    setActiveId(null);
  }
  return (
    <>
      <div className="flex flex-row items-end justify-between">
        <span className="text-xl font-bold leading-6 text-headerBlue">
          Published Careers
        </span>
        {activeId && <button
          type="button"
          onClick={() => {
            let sortedDataIds = items.map((item) => item.id);
            prioritize.mutateAsync({ career_ids: sortedDataIds });
          }}
          className="text-white text-center bg-headerBlue gap-3 pl-1.5 pt-2.5 pr-1.5 pb-2.5 w-[121px] rounded-full text-sm font-normal leading-5"
        >
          Save Changes
        </button>}
      </div>
      <div className="flex flex-wrap max-w-5xl gap-4 mt-4 xl:max-w-6xl">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={items} strategy={rectSortingStrategy}>
            <Grid>
              {items
                .sort((a, b) => a.order as number)
                .map((url, index) => (
                  <SortableCareerItem
                    key={url.id}
                    url={url}
                    index={index}
                    onPressed={onClick}
                  />
                ))}
            </Grid>
          </SortableContext>

          <DragOverlay adjustScale={true}>
            {activeId ? (
              <DragableCareerItem
                url={activeId}
                index={items.indexOf(activeId)}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </>
  );
};

export default CareerDragDrop;
