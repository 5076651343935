import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IChangePassword,
  ILoginUser,
  IUserRegistration,
} from "../interfaces/user.interface";
import http from "./http-service";
import {
  SUBSCRIPTIONS,
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  BASE_URL,
} from "./endpoint-urls";
import { ISubscription } from "../interfaces/subscription.interface";
import { IPriceInfo } from "interfaces/PaymentInterface";
import {
  GenericResponse,
  IPasswordReset,
  IResetPasswordResponse,
} from "interfaces/resetPassword.interface";
import axios from "./http-service";

export const login = createAsyncThunk("login", async (data: ILoginUser) => {
  const response = await http.post(LOGIN_USER, data);
  const resData = response.data;
  return resData;
});

export const getAllSubs = () => {
  return http.get<ISubscription[]>(SUBSCRIPTIONS).then((res) => res.data);
};

export const logout = () => {
  const localStorageValue = localStorage.getItem("userInfo");
  if (localStorageValue) {
    let userInfo = JSON.parse(localStorageValue || "");
    const { refresh } = userInfo;
    return http
      .post<ISubscription[]>(LOGOUT_USER, { refresh: refresh })
      .then((res) => res.data);
  }
  return Promise.reject();
};


export const verifyEmail = async (userId: string|null, token: string|null) => {
  // const response = await http.get<GenericResponse>(
  //   `auth/verify-email/${userId}/${token}/`,
  // );
  // return response;


  return http.post<GenericResponse>(`${BASE_URL}/auth/verify-email`,{uid:userId,token:token})
  .then((res) => {
    return res.data});



};

export const createNewUser = (userDetails: IUserRegistration) => {
  return http.post<IPriceInfo>(REGISTER_USER, userDetails).then((res) => {
    return res.data;
  });
};

export const changePassword = (userDetails: IChangePassword) => {
  return http
    .post<IChangePassword>(CHANGE_PASSWORD, userDetails)
    .then((res) => {
      return res.data;
    });
};
export const forgotPassword = (email: { email: string }) => {
  return http
    .post<IResetPasswordResponse>(FORGOT_PASSWORD, email)
    .then((res) => {
      return res.data;
    });
};

export const resetPassword = (data: IPasswordReset) => {
  return http.post<IResetPasswordResponse>(RESET_PASSWORD, data).then((res) => {
    return res.data;
  });
};
