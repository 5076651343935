import InputError from "components/input-error/input-error";
import {
  FileType,
  IMediaUploadResponse,
  IMediaPicker,
} from "../../interfaces/MediaPickerInterface";
import { useMutation } from "@tanstack/react-query";
import Loading from "components/loading/loading.component";
import { uploadMedia, updateMedia } from "services/career.service";

const VideoPicker: React.FC<IMediaPicker> = ({
  className,
  name,
  svgImgClassName,
  svgPenClassName,
  svgHeight,
  svgWidth,
  media,
  onFileUploaded,
  labelClass,
  order,
  mediaId,
  directoryType,
}) => {
  const uploadNewVideo = useMutation({
    mutationFn: (videoFile: FileType) => {
      if (mediaId) {
        return updateMedia(videoFile, mediaId);
      } else {
        return uploadMedia(videoFile);
      }
    },
    retry: 0,
    onSuccess: (data: IMediaUploadResponse) => {
      onFileUploaded(data, order);
    },
  });

  const isLoading = uploadNewVideo.isPending;

  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const selectedFile = fileInput.files[0];
      let formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);
      formData.append("type", directoryType?directoryType:"career_video");
      uploadNewVideo?.mutateAsync(formData);
    }
  };

  return (
    <div
      className={`flex items-center justify-center w-full h-full ${className}`}
    >
      <label
        htmlFor={`Video_${name}${order}`}
        className={`w-full h-full rounded-md bg-lightBrown  ${labelClass}`}
      >
        <div
          className={` flex flex-col items-center justify-center pt-5 pb-6 cursor-pointer  ${svgImgClassName}`}
        >
          {isLoading ? (
            <Loading />
          ) : media ? (
            <video
              autoPlay={false}
              controls={false}
              className={`absolute rounded-md top-0 ${svgImgClassName}`}
            >
              <source src={media} type="video/mp4" />
            </video>
          ) : (
            <>
              <svg
                width={22}
                height={22}
                viewBox="0 0 35 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.374 12.3409V23.2871C11.374 25.1344 13.2854 26.2921 14.8224 25.3758L24.0051 19.9027C25.5519 18.9815 25.5519 16.6514 24.0051 15.7252L14.8224 10.2521C13.2854 9.33583 11.374 10.4935 11.374 12.3409Z"
                  stroke="#A3A3A3"
                  strokeWidth="2.86621"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M29.1087 2.0498H5.46246C3.28589 2.0498 1.52142 3.81427 1.52142 5.99084V29.6371C1.52142 31.8136 3.28589 33.5781 5.46246 33.5781H29.1087C31.2853 33.5781 33.0497 31.8136 33.0497 29.6371V5.99084C33.0497 3.81427 31.2853 2.0498 29.1087 2.0498Z"
                  stroke="#A3A3A3"
                  strokeWidth="2.86621"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="mt-2 ml-16 ">
                <label className={`absolute ${svgPenClassName}`}>
                  <svg
                    width={svgWidth}
                    height={svgHeight}
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.25 20.25C23.9779 16.5221 23.9779 10.4779 20.25 6.75C16.5221 3.02208 10.4779 3.02208 6.75 6.75C3.02208 10.4779 3.02208 16.5221 6.75 20.25C10.4779 23.9779 16.5221 23.9779 20.25 20.25Z"
                      fill="#404040"
                    />
                    <path
                      d="M17.9202 10.16L16.8398 9.07959C16.4603 8.70003 15.843 8.70003 15.4635 9.07959L14.5718 9.97129L17.0285 12.428L17.9202 11.5363C18.104 11.3526 18.2052 11.108 18.2052 10.8483C18.2052 10.5885 18.104 10.344 17.9202 10.16Z"
                      fill="white"
                    />
                    <path
                      d="M11.3637 13.2921L11.0724 15.4953C11.0567 15.6141 11.0971 15.7335 11.1819 15.8182C11.2547 15.891 11.3529 15.9312 11.4544 15.9312C11.4713 15.9312 11.4879 15.9302 11.5048 15.9278L13.708 15.6364C13.792 15.6254 13.8699 15.5869 13.93 15.5269L16.484 12.9729L14.0273 10.5161L11.4732 13.0701C11.4132 13.1302 11.3747 13.2081 11.3637 13.2921Z"
                      fill="white"
                    />
                    <path
                      d="M17.3403 14.5123C17.1276 14.5123 16.9548 14.6848 16.9548 14.8975V16.6444C16.9548 17.0803 16.6002 17.4349 16.1643 17.4349H10.3554C9.91953 17.4349 9.56496 17.0803 9.56496 16.6444V10.8358C9.56496 10.3999 9.91953 10.0453 10.3554 10.0453H12.1023C12.315 10.0453 12.4878 9.87279 12.4878 9.6601C12.4878 9.44741 12.3153 9.2749 12.1023 9.2749H10.3554C9.49463 9.2749 8.79431 9.97522 8.79431 10.836V16.6447C8.79431 17.5055 9.49463 18.2058 10.3554 18.2058H16.1643C17.0251 18.2058 17.7255 17.5055 17.7255 16.6447V14.8978C17.7255 14.6851 17.5529 14.5126 17.34 14.5126L17.3403 14.5123Z"
                      fill="white"
                    />
                  </svg>
                </label>

                <label className="hidden">Glimpse Image Upload</label>
              </p>
            </>
          )}
        </div>
        <input
          id={`Video_${name}${order}`}
          type="file"
          className="hidden"
          accept="video/*"
          onChange={(event) => handleVideoChange(event)}
        />
      </label>
    </div>
  );
};
export default VideoPicker;
